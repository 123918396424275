import React, { useEffect, useState } from 'react'
import Layout from '../../components/Layout'
import WhoWeAre from '../../components/careers/WhoWeAre'
import PartOfLenses from '../../components/careers/PartOfLenses'
import BeYou from '../../components/careers/BeYou'
import HeaderSEO from '../../components/partials/HeaderSEO'
import { Helmet } from 'react-helmet'

interface PageSEO {
  description: Description
  keywords: Keywords
  title: Title
  oGimage: OGimage
}

interface OGimage {
  fluid: Fluid
}
interface Fluid {
  src: string
}
interface Keywords {
  keywords: string
}
interface Description {
  description: string
}
interface Title {
  title: string
}
interface StateProps {
  LocationState: LocationData
}

interface LocationData {
  roleLocation: string
  link: string
}

const salesEngineer: React.FC<StateProps> = () => {
  const [locationData, setLocationData] = useState<LocationData>({
    roleLocation: 'London, UK',
    link: 'https://apply.workable.com/lensesio/j/084F23215B/',
  })
  const { roleLocation, link } = locationData

  useEffect(() => {
    var url_string = window.location.href
    var url = new URL(url_string)
    var location = url.searchParams.get('location')
    if (location == 'us') {
      setLocationData({
        roleLocation: 'New York, NY',
        link: 'https://apply.workable.com/lensesio/j/EA9520F152/',
      })
    }
  }, [])

  // Matching to HeaderSEO format
  const pageSEO: PageSEO = {
    description: {
      description:
        'We are looking for a Sales Engineer in London and New York. Apply to join our team!',
    },
    keywords: {
      keywords:
        'Sales Engineer, London,New York,Lenses careers,Hiring,Job opening',
    },
    oGimage: {
      fluid: {
        src: '//images.ctfassets.net/tnuaj0t7r912/3Bym5KKf8LlKe7WB9DGVEk/ce37a1c015bf72509e8201a6a1e4cd4a/sales-engineer.jpg',
      },
    },
    title: {
      title: 'Sales Engineer',
    },
  }

  return (
    <Layout>
      <HeaderSEO pageSEO={pageSEO} />
      <Helmet>
        <link
          rel="canonical"
          href="https://lenses.io/careers/sales-engineer/"
        />
      </Helmet>
      <div className=" bg-dark py-5 my-5"></div>
      <br />
      <br />
      <div className="container-1 mb-5">
        <div className="card offset-md-2 col-md-8 col-sm-12">
          <div className="card-body pl-4 pr-4">
            <section className="section section--header">
              <div className="roll-button">
                <a
                  href="/careers/#openpositions"
                  className="bg-red text-white btn-xs mr-0 border-0 all-jobs-negative-margin"
                >
                  <i className="fa fa-arrow-left mr-1"></i>
                  <span data-hover="All jobs">All jobs</span>
                </a>
              </div>
            </section>
          </div>
          <h1 className="h3-like">Sales Engineer</h1>
          <p className="meta">{roleLocation} </p>
          <section className="section section--text">
            <WhoWeAre />

            <h5 className="pt-3">What your day-to-day looks like:</h5>

            <ul className="pl-4 ">
              <li>
                Lead technical engagements where we prove the potential of
                Lenses through discovery, demos, and POCs
              </li>
              <li>
                Become a trusted advisor for our customers offering
                best-practices, recommendations, and tips
              </li>
              <li>
                Be a key player in scaling our US business, working closely with
                sales, marketing, & engineering teams
              </li>
              <li>
                Engage with our customer base and in our vibrant community
              </li>
              <li>
                Be one of our evangelists in the US for Lenses.io and DataOps
                with customers, and through engaging content
              </li>
            </ul>

            <h5 className="pt-3">What you will bring:</h5>

            <ul className="pl-4 ">
              <li>
                Selling into engineering or operations teams in large
                organizations with a go-getter attitude
              </li>
              <li>
                Ability to present strong technical arguments and best practices
                bringing inspiration to customers
              </li>
              <li>
                Good knowledge of streaming or messaging systems such as Apache
                Kafka, Rabbit MQ, JMS, as well as Linux operating systems and
                first principles
              </li>
              <li>
                Aptitude in system administration and cloud services and some
                experience working in data analytics
              </li>
              <li>
                Strong emotional intelligence helping product/tech teams to
                navigate a customer&apos;s challenges
              </li>
              <li>
                Business acumen, strong critical thinking, and root-cause
                analysis as well as strong written and oral skills
              </li>
              <li>
                Working with integrity, proactively follow new trends and
                technologies in the data analytics and engineering markets
              </li>
              <li>
                Experience in building, running, or architecting distributed
                technologies and/or selling DevOps tooling is a bonus
              </li>
            </ul>

            <PartOfLenses />
          </section>

          <section>
            <BeYou link={link} />
          </section>
        </div>
      </div>
    </Layout>
  )
}

export default salesEngineer
